<template>
	<main>
		<div class="el-content">
			<table-search v-model="search_data" :column="column" @clean="clean" @search="search"/>
			<div class="action-box">
				<div class="action">
					<el-button class="el-icon-refresh" @click="all"></el-button>
					<el-popconfirm title="确定要删除选中的数据吗？" @confirm="deleteChoose">
						<template #reference>
							<el-button icon="el-icon-delete">删除</el-button>
						</template>
					</el-popconfirm>
					<template v-if="import_visible">
						<el-button class="el-icon-download" @click="templateFile">下载导入模板</el-button>
						<el-button class="upload-btn el-icon-upload" :loading="upload_loading">批量导入
							<input type="file" id="file" @change="uploadFile" accept=".xlsx">
						</el-button>
					</template>
				</div>
				<div class="other">
					<table-column :column="column" @change="changeColumn"/>
					<el-button v-if="export_visible" class="el-icon-download" @click="exportAll" :loading="export_loading" title="导出"></el-button>
				</div>
			</div>
			<div class="table-box">
				<table-table :table_loading="table_loading" :table_data="table_data" :column_visible="column_visible" :column="column" @selection="selection" @sortChange="sortChange" @changeSort="changeSort">
					<template #column="{scope,item}"></template>
					<template #action="{scope}">
						<div class="el-link-group">
							<el-link type="primary" @click="editor(scope.row)">编辑</el-link>
							<el-popconfirm v-if="scope.row.status === 1" title="确定要同意该用户吗？" @confirm="start(scope.row)">
								<template #reference>
									<el-link type="primary">同意</el-link>
								</template>
							</el-popconfirm>
							<el-popconfirm v-if="scope.row.status === 3" title="确定要启用该用户吗？" @confirm="start(scope.row)">
								<template #reference>
									<el-link type="primary">启用</el-link>
								</template>
							</el-popconfirm>
							<el-popconfirm v-if="scope.row.status === 2" title="确定要禁用该用户吗？" @confirm="pause(scope.row)">
								<template #reference>
									<el-link type="primary">禁用</el-link>
								</template>
							</el-popconfirm>
						</div>
					</template>
				</table-table>
			</div>
			<div class="page-box">
				<el-pagination @size-change="sizeChange" @current-change="currentChange" :current-page="page_info.page" :page-sizes="[10, 15, 20, 50,100]" :page-size="page_info.limit" layout="total,sizes,prev,pager,next,jumper" :total="page_info.total" hide-on-single-page background></el-pagination>
			</div>
		</div>
		<components-create v-if="create_visible" ref="createRef" :title="title" :column_option="column_option" @success="all" @close="closeCreate"/>
		<table-import v-if="import_visible" ref="importRef" :import_field="import_field" @saveAll="saveAll"/>
	</main>
</template>
<script>
import ComponentsCreate from "./Create.vue"
import {MixinIndex} from "@/service/admin/mixinIndex";
import {user as api} from "@/api/admin";

export default {
	components: {ComponentsCreate},
	mixins: [MixinIndex],
	data() {
		return {
			api,
			title: "用户",
			column_option: {
				role: [{id: 1, title: "普通员工"}, {id: 2, title: "维修员"}, {id: 3, title: "维修主管"}],
				is_admin: [{id: 1, title: "是"}, {id: 2, title: "否"}],
				status: [{id: 1, title: "待审核"}, {id: 2, title: "正常"}, {id: 3, title: "已封禁"}],
			},
		}
	},
	computed: {
		column() {
			return [
				{label: "ID", prop: "id", sortable: true},
				{label: "唯一标识", prop: "uuid"},
				{label: "头像", prop: "avatar", type: "image"},
				{label: "姓名", prop: "username", search: true},
				{label: "工号", prop: "code", search: true},
				{label: "手机号码", prop: "phone", search: true},
				{label: "所属部门", prop: "department.title"},
				{label: "权限", prop: "role", type: "option", search: true, option: this.column_option.role},
				{label: "管理员", prop: "is_admin", type: "option", search: true, option: this.column_option.is_admin},
				{label: "最近登录时间", prop: "login_time", type: 'datetime', sortable: true},
				{label: "注册时间", prop: "create_time", type: 'datetime', sortable: true},
				{label: "状态", prop: "status", type: "option", search: true, option: this.column_option.status},
				{label: "操作", prop: "action", fixed: 'right', type: 'action'},
			]
		}
	},
	methods: {
		start(row) {
			api.start({
				id: row.id
			}).then((res) => {
				this.$message.success('操作成功');
				this.all()
			})
		},
		pause(row) {
			api.pause({
				id: row.id
			}).then((res) => {
				this.$message.success('操作成功');
				this.all()
			})
		}
	}
}
</script>
