<template>
	<main>
		<el-dialog :visible="true" @close="close">
			<template slot="title">
				<div class="title">{{ query.id ? '编辑' : '创建' }}{{ title }}</div>
			</template>
			<div class="form-box">
				<el-form class="create-form" ref="formRef" :rules="rules" :model="form" label-width="100px">
					<el-form-item label="唯一标识" prop="uuid">
						<el-input v-model="form.uuid" disabled/>
					</el-form-item>
					<el-form-item label="姓名" prop="username">
						<el-input v-model="form.username"/>
					</el-form-item>
					<el-form-item label="头像" prop="avatar">
						<form-upload v-model="form.avatar" accept="image"/>
					</el-form-item>
					<el-form-item label="部门" prop="department_id">
						<form-api v-model="form.department_id" :api="department.all"/>
					</el-form-item>
					<el-form-item label="工号" prop="code">
						<el-input v-model="form.code"/>
					</el-form-item>
					<el-form-item label="手机号" prop="phone">
						<el-input v-model="form.phone"/>
					</el-form-item>
					<el-form-item label="权限" prop="role">
						<el-radio-group v-model="form.role">
							<el-radio v-for="(item,index) in column_option.role" :key="index" :label="item.id">{{ item.title }}</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="管理员" prop="is_admin">
						<el-radio-group v-model="form.is_admin">
							<el-radio v-for="(item,index) in column_option.is_admin" :key="index" :label="item.id">{{ item.title }}</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="状态" prop="status">
						<el-radio-group v-model="form.status">
							<el-radio v-for="(item,index) in column_option.status" :key="index" :label="item.id">{{ item.title }}</el-radio>
						</el-radio-group>
					</el-form-item>
				</el-form>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="close">取 消</el-button>
				<el-button type="primary" :loadin="submit_loading" @click="submit">保 存</el-button>
			</div>
		</el-dialog>
	</main>
</template>

<script>
import {user as api, department} from "@/api/admin";
import {MixinCreate} from "@/service/admin/mixinCreate";

export default {
	mixins: [MixinCreate],
	data() {
		return {
			api,
			department,
		}
	},
	computed: {
		rules() {
			return {
				title: [
					{required: true, message: "请输入标题"}
				],
				content: [
					{required: true, message: "请输入正文"}
				]
			}
		}
	}
}
</script>
